 .PatientListTable::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
  }
  .PatientListTable::-webkit-scrollbar-track {
    background: rgb(197, 196, 193);        /* color of the tracking area */
  }
  .PatientListTable::-webkit-scrollbar-thumb {
    background-color: rgb(109, 109, 131);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid rgb(199, 199, 199);  /* creates padding around scroll thumb */
  }
  .border{
    color: black;
  }
  .PTC1{
    text-align: start !important;
    /* padding: 5px !important; */
    font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
    /* color: #7A869A !important; */
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 1.66666667 !important;
    letter-spacing: 0 !important;
    text-transform: none !important;
    padding: 4px !important;
  }
  .Patient-List-Action{
    text-align: center !important;
    /* padding: 5px !important; */
    font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
    /* color: #7A869A !important; */
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 1.66666667 !important;
    letter-spacing: 0 !important;
    text-transform: none !important;
    padding: 10px !important;
  }
  .Patient-List-Action1{
    padding: 5px !important;
    text-align: end !important;
    font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
    /* color: #7A869A !important; */
    font-size: 12px !important;
    /* font-weight: 600 !important; */
    line-height: 1.66666667 !important;
    letter-spacing: 0 !important;
    text-transform: none !important;
  }
  .PTC{
    padding: 5px !important;
    text-align: start !important;
    font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
    font-size: 12px !important;
    /* font-weight: 600 !important; */
    line-height: 1.66666667 !important;
    letter-spacing: 0 !important;
    text-transform: none !important;
  }

  .css-bhp9pd-MuiPaper-root-MuiCard-root{
    text-align: center !important;
  }
  .PatientIcon{
    font-size: 40px  !important;
    color:#2c6bd7!important;
  }
  .plusCircle{
    font-size: larger !important;
    color: #2c6bd7 !important;

  }

  .Action_Button{
    font-size: 10px !important;
    padding: 5px 8px !important;
    margin-left: 4px !important;
    border-radius: 2px !important;
  }
  .Delete-Action-Button{
    font-size: 10px !important;
    padding: 5px 8px !important;
    background-color: #e64a19 !important;
    border-radius: 2px !important;
  }
  .dialog-Cancel-button{
    background-color: #388e3c !important;
    border-radius: 2px !important;
    width: 36% !important;
  }
  /* .dialog-Cancel-button:hover{
    background-color: green !important;
    color: black !important;
  } */
  .dialog-Delete-button{
    background-color: #e64a19 !important;
    border-radius: 2px !important;
    width: 36% !important;
  }

  .modal-content{
    border-radius: 3px !important;
  }
  .Modal-save-button{
    outline: none;
    border: 1px solid #56ea56;
    width: 80px;
    border-radius: 3px;
    background: transparent;
    color: #56ea56;
  } .Modal-save-button:hover{
    background-color:#56ea56;
    color: black;
  }
  .Model-Cross{
    background: transparent;
    border: none;
    outline: none;
    color: #eb4f4f;
  }
.pl-child-div{
  width: 100%;
  overflow: hidden;
  padding: 0px 0px 10px;
}

.data-table-extensions > .data-table-extensions-filter {
  display: flex;
  float: left;
  border: 1px solid lightgray;
  border-radius: 2px;
  align-items: center;

}
.data-table-extensions{
  background-color: white;
}
