.common-Labels{
    font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}
.common-button{
  font-size: 10px !important;
  padding: 5px 8px !important;
  margin-left: 4px !important;
  border-radius: 2px !important;
}
.common-button:hover{  
    background-color: #104298;
    color: white;
}
.common-Input{
  border-radius: 3px;
  font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
  font-size: 12px !important;
  outline: none !important;
}
.common-FontFamily{
  font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
}
.common-FontSize-small{
  font-size: 12px !important;
}
.common-Font-10{
  font-size: 10px !important;
}
.common-FontWeight{
  font-weight: 600 !important;
}
.commont-Font-Bold{
  font-weight:700 !important;
}
.common-modal{
  border-radius: 0% !important;
}
.common-color{
  color: #2c6bd7;
}
.common-cross-button{
  background: transparent;
  outline: none;
  border: none;
}

.common-Asterisk{
  color: red;
  font-size: 15px;
}
.common-cursor{
  cursor: pointer;
}

.common-Textarea{
  border-radius: 0%;
  font-family: "-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue","sans-serif" !important;
  font-size: 12px !important;
  outline: none !important;
  max-height: 130px;
  height: 32px;
}
.common-setting-button{
    background-color: gray;
    color: white;
    border-radius: 2px;
    cursor: pointer;
    opacity: 1;
    border: 1px solid #a4aab5;
    width: 85px;
    height: 30px;
    font-size: 12px;
}.common-setting-button:hover{
  background-color: rgb(107, 107, 107);
}

.common-text-start{
  text-align: start;
}
.common-text-center{
  text-align: center;
}
.common-text-end{
  text-align: end;
}
.common-box-shadow{
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2)!important;
} 
.common-box-border{
  border: 1px solid lightgray;
  border-radius: 3px;
}
.common-br-3px{
  border-radius: 3px;
}

.common-Link{
    color: #0d6efd !important;
    cursor: pointer;
}
.Common-min-height{
  /* min-height: calc(var(--vh, 1vh) * 100); */
  min-height: calc(var(--vh, 1vh)* 90);
}
.common-line-height{
  line-height: 12px;
}
.common-background-color{
  background-color:#f4f6f9
}
.common-bg-white{
  background-color: white !important;
}