
.US-tr{
  background-color: #2c6bd7;  
}
.UM-label{
    padding: 3px 0px 0px 10px;
}

.Action_Button{
  font-size: 10px !important;
  padding: 5px 8px !important;
  margin-left: 4px !important;
  border-radius: 2px !important;
}