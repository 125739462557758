.css-1du4edq-MuiListItemIcon-root{
    min-width: 0px !important;
}
.css-iyphud-MuiListItemText-root {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: white;
  margin-top: 0px !important;
}

/* .css-1nxmd3h-MuiListItem-root{
    padding: 5px 5px 5px 0px;

} */
.Horizental-bar{
  box-shadow: none !important;
}
.InputSearch{
  border: 1px solid lightgray;
  width: 30%;
  border-radius: 5px;
  padding: 1px;
  }
  .searchButton{
    border: none;
    font-size: smaller;
    color: lightgrey;
    background-color: white;
    cursor: pointer;
  }
 
 .InputSearch input::placeholder {
    color: rgb(201, 201, 201); /* Change this to the color you want */
    opacity: 1;  /* Ensures the placeholder color is fully opaque */
  }
  .css-1lyul1r{
    padding: 3px !important;
    box-shadow: none !important;
    border-bottom: 1px solid lightgray;
  }
  .logo{
    width: 50px;
    left: 0;
    position: absolute;
  }
  .lab-name{
    margin-left: 5rem;
  }
  .parent-inputsearch{
    width: 90%;
  }
  .search-tbl{
    overflow: auto;
    list-style: none;
    background-color: white;
    width: 30%;
    text-align: xenter;
    left: 43%;
    position: relative;
    text-align: start;
    padding: 0px;
    border: 1px solid lightgray;
  }
  .search-tbl tr{
    background-color: #ffff;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: #e0e0e0;
    margin-top: 4px;
  } .search-tbl tbody tr:hover {
    background-color: #e3f2fd; /* Change background color on hover */
  }
  .search-clear-btn{
    width: 100%;
    border: none;
    outline: none;
    height: auto;
    background: transparent;
  }
  .css-1jp3dsi {
    flex: 1 1 auto;
    min-width: 0px;
    margin-bottom: 0px !important;
    color: white;
    margin-top: 0px !important;
  }
  .css-4memx8-MuiTypography-root{
   font-weight: 100 !important;
  }
  .setting-menu{
    background-color: #2c6bd7;;
  }
  .MuiList-root{
    background-color: #2c6bd7 !important;
  }
  .css-lqst0b-MuiAvatar-root{
    font-size: 15px !important;
    width: 33px !important;
    height: 33px !important;
  }
   